@use '../../../../src/styles/scss/variables' as vars;

.change-password {
  padding-top: 25px;
  padding-bottom: 45px;
  padding-right: 27px;
  background-color: vars.$white;

  .content {
    padding-left: 30px;
    padding-right: 30px;

    .content-head {
      padding-top: 35px;

      p {
        margin: 0;
        font-family: Poppins;
        font-size: 12px;
        font-weight: vars.$font-weight-normal;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
      }

      h4 {
        margin: 0;
        font-size: 30px;
        font-weight: vars.$font-weight-semibold;
        line-height: 45px;
        letter-spacing: 0px;
        text-align: left;
        color: #495057;
      }
    }

    .content-form {
      padding-top: 35px;

      .button-submit {
        display: flex;
        justify-content: flex-end;

        button {
          height: 57.11px;
          font-weight: vars.$font-weight-mdbold;
          font-size: 18px;

        }
      }
    }
  }

  .warning-box {
    padding-top: 110px;
    padding-left: 30px;

    .bg-warning {
      display: flex;
      background-color: rgba(224,
          114,
          67,
          0.25);
      /* use rgba for background color */
      padding-top: 35px;
      flex-direction: column;
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 40px;
      border-radius: 5px;

      .title {
        p {
          font-weight: vars.$font-weight-mdbold;
          font-size: 14px;
          line-height: 21px;
          color: #3626a7;
        }
      }

      .info {
        p {
          text-align: justify;
        }
      }

      .heading {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
  }
}
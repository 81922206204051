@use "styles/scss/variables" as vars;

.help-layout {
  padding-top: 25px;
  padding-bottom: 45px;
  padding-right: 27px;
  background-color: vars.$white;

  .help-content {
    display: flex;
    flex-direction: column;
    padding-top: 25px;
    padding-left: 30px;

    .help-heading {
      h3 {
        margin: 0;
      }
    }

    .help-desc {
      padding-top: 20px;

      p {
        line-height: 18px;
        font-size: 17px;
        color: vars.$subtle-color-text-3;
        margin: 0;
      }
    }

    .help-info {
      padding-top: 60px;
      padding-right: 15px;
    }
  }

  .help-card {
    display: flex;
    background-color: vars.$white;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    flex-direction: column;

    .help-card-header {
      display: flex;
      padding: 45px 65px;
      flex-direction: column;
      border-bottom: 1px solid #ebebeb;

      h4 {
        display: flex;
        justify-content: center;
        margin: 0;
        padding-bottom: 5px;
        font-size: 24px;
        font-weight: vars.$font-weight-bold;
      }

      p {
        margin: 0;
        font-size: 18px;
        font-weight: vars.$font-weight-mdbold;
        color: vars.$text-color-1;
        text-align: center;
      }
    }

    .help-card-content {
      display: flex;
      flex-direction: column;
      padding-top: 35px;
      padding-left: 30px;
      padding-right: 30px;

      .help-card-box {
        display: flex;
        background: #ececec;
        height: 229.44px;
        width: 100%;
        border-radius: 5px;

        textarea {
          width: 100%;
          background-color: transparent;
          outline: none;
          border: 0;
          padding: 21px 27px;
          border-radius: 5px;
          resize: none;
        }

        p {
          margin: 0;
          padding-top: 25px;
          padding-left: 25px;
          font-size: 15px;
        }
      }

      .help-card-button {
        padding-bottom: 40px;
        width: 100%;
        display: flex;
        padding-top: 25px;

        Button {
          height: 57.11px;
          width: 100%;

          p {
            margin: 0;
            color: #fffdfd;
            font-size: 18px;
            font-weight: vars.$font-weight-mdbold;
          }
        }
      }
    }
  }

  .info-card {
    padding: 25px 35px;
    display: flex;
    border-radius: 5px;
    background-color: vars.$white;
    gap: 30px;
    box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.15);
    width: 100%;

    .ic-left {
      background: #e9ffef;
      border-radius: 50px;
      width: 46px;
      height: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }

    .ic-right {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 7px;
      flex: 1;

      h3,
      p {
        margin: 0;
      }

      p {
        width: 90%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    @media (max-width: 600px) {
      padding: 20px;
      gap: 15px;
    }
  }
}
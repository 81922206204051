@mixin active-link($from: "") {
  &:before {
    opacity: 1;
  }
  @if $from == "home" {
    font-weight: $font_weight_bold;
    color: $primary_color;
  }
  @if $from == "sidebar" {
    font-weight: $font_weight_bold;
    color: $primary_color;
  }
  @if $from == "" {
    color: $white !important;
  }
}

// container mixins

@mixin container() {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 476px) {
    padding-right: 15px;
    padding-left: 15px;
  }

  @media (min-width: 768px) {
    padding-right: 15px;
    padding-left: 15px;
  }

  @media (min-width: 992px) {
    padding-right: 15px;
    padding-left: 15px;
  }

  @media (min-width: 1200px) {
    padding-right: 15px;
    padding-left: 15px;
  }

  @media (min-width: 476px) {
    width: 100%;
  }

  @media (min-width: 768px) {
    width: 800px;
    max-width: 100%;
  }

  @media (min-width: 992px) {
    width: 960px;
    max-width: 100%;
  }

  @media (min-width: 1200px) {
    width: 1140px;
    max-width: 100%;
  }

  @media (min-width: 1400px) {
    width: 1340px;
    max-width: 100%;
  }
}

@mixin font-size-variant($size: "") {
  @if $size == "huge" {
    font-size: $font_size_huge;
  }
  @if $size == "xlarge" {
    font-size: $font_size_xlarge;
  }
  @if $size == "medium" {
    font-size: $font_size_medium;
  }
  @if $size == "small" {
    font-size: $font_size_small;
  }
  @if $size == "xsmall" {
    font-size: $font_size_xsmall;
    line-height: 1.5;
  }
}

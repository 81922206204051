// positions

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

// utils

.d-none {
  display: none;
}

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

.uppercase {
  text-transform: uppercase;
}

.cursor-pointer {
  cursor: pointer;
}

.m-0 {
  margin: 0;
}

.line-height-25 {
  line-height: 25px;
}

.line-height-30 {
  line-height: 30px;
}

.text-center {
  text-align: center;
}

// weights

.font-semibold {
  font-weight: $font-weight-semibold;
}

.font-bold {
  font-weight: $font-weight-bold;
}

// text sizes

.text-small {
  font-size: $font-size-small;
}

.text-normal {
  font-size: $font-size-normal;
}

.text-18 {
  font-size: 18px;
}

.text-medium {
  font-size: $font-size-medium;
}

.text-xlarge {
  font-size: $font-size-xxlarge;
}

.text-huge {
  font-size: $font-size-huge;
}

// text colors

.primary-text-color {
  color: $primary-color;
}

.white-color-forced {
  color: $white !important;
}

.primary-text-lighten-10 {
  color: lighten($color: $primary-color, $amount: 10);
}

// layout

.container {
  @include container();
}

// btn



.btn-orange {
  background: #E07243 !important;

  &:hover {
    background: lighten($color: #E07243, $amount: 10%) !important;
  }

}

.btn-shadow {
  box-shadow: 0px 7px 20px rgba(0, 121, 254, 0.35);
}

// fonts

// backgrounds

.bg-color {
  background: $white;
}

// flex custom classes

.d-flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.justify-content-center {
  justify-content: center;
}

.df-col-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.df-col-start-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.df-row-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.abs-centered {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

// fonts

.font-work-sans {
  font-family: "Work Sans" !important;
}

// letter spacing

.letter-spacing-4 {
  letter-spacing: 4px;
}

.letter-spacing-2 {
  letter-spacing: 2px;
}

.section-margin-top {
  margin-top: $section-margin-top;
}

.card-common-padding-x {
  padding-left: 30px;
  padding-right: 30px;
}

// backgrounds
.bg-white {
  background-color: $white;
}

// colors

.form-error-message {
  color: $red;
  font-weight: $font-weight-semibold;
  font-size: $font-size-small;
  margin-bottom: 8px;

}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
// colors
$primary-color: #3626a7;
$default-color: #939393;
$grey: #727272;
$black: #292929;
$white: #ffffff;
$light-grey: #ededed;
$red: #ff0000;
$blue: #4e78f4;
$light-blue: #657ed4;


// status colors
$success-color: #00b300;
$warning-color: #ffb300;
$error-color: #ff0000;
$info_color: #17a2b8; // Info color (light blue)
$danger_color: #dc3545; // Danger color (red)

// backgrounds
$page-background-color: #f5f5f5;
$auth-background-color: #f5f5f5;

// font sizes
$font-size-xsmall: 0.625rem;     // 10px / 16px
$font-size-small: 0.75rem;       // 12px / 16px
$font-size-base: 0.875rem;       // 14px / 16px
$font-size-normal: 1rem;         // 16px / 16px
$font-size-medium: 1.5rem;       // 24px / 16px
$font-size-large: 1.75rem;       // 28px / 16px
$font-size-xlarge: 2rem;         // 32px / 16px
$font-size-xxlarge: 2.5rem;      // 40px / 16px
$font-size-huge: 3.3375rem;      // 55px / 16px

// $font-size-xsmall: 10px;
// $font-size-small: 12px;
// $font-size-base: 14px;
// $font-size-normal: 16px;
// $font-size-medium: 24px;
// $font-size-large: 28px;
// $font-size-xlarge: 32px;
// $font-size-xxlarge: 40px;
// $font-size-huge: 55px;

// font weights for normal , semibold , and bold
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 500;
$font-weight-mdbold: 600;
$font-weight-bold: 700;
$font-weight-xbold: 800;

// text colors
$default-text-color: #051441;
$text-color-2: #374151;
$text-color-light-dark: #2f2f2f;
$primary-text-color: #3626a7;
$subtle-color-text: #6c757d;
$subtle-color-text-2 : #8F8F8F;
$subtle-color-text-3 : #74788D;
$text-color-1 : #495057;

// header height
$page-navbar-height: 80px;

// auth form

$auth-form-background-color: #f2f2f2;

// line heights

$line-height-25: 25px;



// dashboard

$dashboard-bg-color : #f9f9f9;
$footer-bg-color : #f9f9f9;
$dashboard-sidebar-item : #f9f9f9;


$dashboard-content-padding : 25px 35px;
$dashboard-header-padding : 0 35px;


// margins

$section-margin-top : 35px;


// fonts
$font-lato : 'Lato';
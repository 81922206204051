h1 {
  font-size: $font-size-xlarge;
}

h2 {
  font-size: $font-size-large;
}

h3 {
  font-size: $font-size-medium;
}

h4 {
  font-size: $font-size-base;
}

h5 {
  font-size: $font-size-small;
}

p {
  font-size: $font-size-base;
  color: $default-text-color;
}


@media (max-width: 1280px) {
  html {
    font-size: 14px;
  }
}
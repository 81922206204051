@use "styles/scss/variables.scss" as vars;

.login-form-wrapper {
  form {
    margin-top: 22px;
  }

  .social-btns-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 20px 0;

    .social-button {
      width: 100%;
      padding: 18px 10px;
      background-color: vars.$white;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.11);
      border-radius: 8px;
      border: 0;
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: center;
      color: vars.$text-color-light-dark;
      font-weight: vars.$font-weight-semibold;
    }
  }
  .forgot-text {
    margin: 20px 0;
    text-align: right;
  }

  .register-text {
    margin-top: 30px;
  }
}

.payment-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; // Adjust as needed

    .payment-form {
        max-width: 450px;
        width: 100%;
        padding: 30px 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        background-color: white;

        .field-container {
            margin-bottom: 10px;


            &>label {
                margin-bottom: 8px;
                display: block;
                font-weight: 500;
            }


            .StripeElement {
                margin-bottom: 20px;
                padding: 10px 15px;
                border: 1px solid #cccccc55;
                border-radius: 4px;

                &:focus {
                    border-color: #80bdff;
                    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
                }

            }
        }

    }
}
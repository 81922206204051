@use '../../../../src/styles/scss/variables' as vars;

.comparison-col-layout {
  display: flex;
  background-color: vars.$white;
  flex-direction: column;
  box-shadow: 0px 10px 20px rgba(62, 73, 84, 0.1);

  .comp-header {
    padding-left: 25px;
    padding-top: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #ebebeb;
  }



  .comp-content {
    padding-left: 25px;
    padding-top: 25px;
    padding-right: 25px;
    padding-bottom: 25px;

    .cards {
      padding-top: 15px;
      border-radius: 5px;
      box-shadow: 0px 10px 20px rgba(62, 73, 84, 0.1);
    }
  }
}

.comp-search-bar {
  display: flex;
  justify-content: space-between;
  padding: 25px 35px;
  background: #efefef;
  position: relative;

  input {
    color: vars.$subtle-color-text-3;
    font-weight: vars.$font-weight-semibold;
    width: 100%;
    height: 100%;
    outline: none;
    background: #efefef;
    border: 0;

    &::placeholder {
      color: vars.$subtle-color-text-3;
    }

  }

  .search-icon {
    position: absolute;
    top: 28px;
    right: 20px;
  }
}

.top-tags-layout {
  display: flex;
  flex-direction: column;


  .tags-header {
    padding-top: 20px;
    padding-left: 25px;
    padding-bottom: 10px;

    p {
      margin: 0;
      font-size: 20px;
      font-weight: 700;
      color: vars.$black;
    }
  }

  .tags-content {
    padding-left: 25px;
    padding-bottom: 35px;

    ol {
      margin: 0;
      padding-inline-start: 20px;
    }

    li {
      margin: 0;
      color: vars.$subtle-color-text-3;

      p {
        color: vars.$subtle-color-text-3;
      }
    }
  }
}
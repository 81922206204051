@use '../../../../src/styles/scss/variables' as vars;

.about-us-dashboard {
  padding-top: 25px;
  padding-left: 25px;
  padding-bottom: 45px;
  padding-right: 27px;
  background-color: vars.$white;
}

.card-variant-listing {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.05);

  .card-temp-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-left: 29px;
    padding-right: 29px;
    padding-bottom: 30px;
    padding-top: 25px;
    border-bottom: 1px solid #ebebeb;
    gap: 5px;

    .card-cat {
      margin: 0;
      font-weight: 500;
      font-size: vars.$font-size-base;
      color: vars.$subtle-color-text-3;
    }

    .card-art {
      margin: 0;
      display: flex;
      max-width: 202px;
      text-align: center;
      font-weight: vars.$font-weight-mdbold;
      font-size: vars.$font-size-base;
      // line-height: 21px
    }
  }

  .card-temp-content {
    display: flex;
    flex-direction: column;
    padding-left: 29px;
    padding-right: 29px;
    padding-bottom: 30px;
    padding-top: 25px;
    border-bottom: 1px solid #ebebeb;
    gap: 18px;

    .ctv-row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 5px;

      p {
        margin: 0;
      }

      .ctv-row-label {
        font-weight: vars.$font-weight-normal;
        color: vars.$subtle-color-text-3;
        font-size: vars.$font-size-base;
      }

      .ctv-row-value {
        font-size: 16.25px;
        font-weight: vars.$font-weight-semibold;
      }
    }
  }
}

.card-variant-listing-2 {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .card-header {
    display: flex;
    gap: 20px;

    .heading {
      p {
        margin: 0px;
        font-size: 20px;
        line-height: 24.38px;
        font-weight: vars.$font-weight-semibold;
      }
    }
  }

  .card-content {
    p {
      margin: 0px;
      font-size: vars.$font-size-base;
      line-height: 20px;
      font-weight: vars.$font-weight-semibold;
      color: vars.$subtle-color-text-3;
      word-wrap: break-word;
    }

    mark {
      cursor: pointer;
    }
  }
}
@use '../../../../src/styles/scss/variables' as vars;

.profile-header {
  background-color: vars.$white;
  width: 100%;
  display: flex;
  height: auto;
  justify-content: space-between;
  padding-top: 17px;
  padding-left: 40px;
  padding-bottom: 17px;
  padding-right: 35px;
  gap: 15px;
  flex-wrap: wrap;

  .profile-header-left {
    display: flex;
    gap: 15px;
    align-items: center;

    .profile-avatar-wrapper {
      width: 93px;
      height: 93px;
      border-radius: 50%;
      position: relative;
      background-color: #49505720;


      .picker-icon {
        position: absolute;
        bottom: 0;
        right: 0;
        cursor: pointer;
      }

      >img:not(.picker-icon) {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }


    }

    .user-info {
      text-align: left;
      display: inline-flex;
      gap: 3px;
      flex-direction: column;

      p {
        color: vars.$subtle-color-text-2;
      }

      .username {
        color: vars.$black !important;
        font-weight: vars.$font-weight-mdbold;

      }
    }
  }

  .profile-header-right {
    display: flex;
    gap: 15px;
    align-items: center;
    flex-wrap: wrap;

    &>Button {
      height: 50px;
      font-size: 18px;
      font-weight: vars.$font-weight-mdbold;


      &:first-child {
        color: #fffdfd;
      }
    }

  }
}

.info-section {
  background-color: vars.$white;
  width: 100%;
  display: flex;
  height: auto;
  padding-top: 17px;
  padding-left: 40px;
  padding-bottom: 17px;
  padding-right: 35px;
  gap: 15px;
  flex-direction: column;

  .password-field-col {
    position: relative;


    .custom-input-wrapper {
      input {
        padding-right: 100px;
      }
    }

    .change-password-button {
      position: absolute;
      top: 15px;
      right: 20px;


    }

  }

  .heading {
    p {
      margin: 0;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0px;
      text-align: left;
      padding-top: 10px;
    }

    h4 {
      margin: 0;
      font-size: 30px;
      font-weight: 500;
      line-height: 45px;
      letter-spacing: 0px;
      text-align: left;
      color: #495057;
    }
  }

  .forms {
    padding-top: 17px;
  }

  .delete-button {
    display: flex;
    gap: 15px;
    width: 100%;
    justify-content: flex-end;
    margin-top: 20px;

    Button {
      height: 57.11px;
      //   width: 100%;

      color: #fffdfd;
      font-size: 18px;
      font-weight: vars.$font-weight-mdbold;
    }
  }
}

@media screen and (max-width: 480px) {
  .profile-header {
    padding-right: 15px;
    padding-left: 15px;
    flex-direction: column;
  }

  .dashboard-header-right {
    padding-top: 15px;
  }
}
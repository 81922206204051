@use 'styles/scss/variables.scss' as vars;

$button-height : 36px;
$text-color-2 : #252525;


.custom-calendar {
    width: 100%;
    height: 100%;
    background-color: vars.$white;
    padding: 18px 20px;
    box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.05);
    border-radius: 8px;
    // overwrites 


    .rbc-today {
        background-color: rgba($color: vars.$primary-color, $alpha: 0.3);
    }



    .rbc-current {
        .rbc-button-link {
            font-weight: bold;
            color: white;
        }
    }



    .custom-toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        margin-bottom: 30px;
        flex-wrap: wrap;


        button {
            height: $button-height !important;
            border-radius: 4px;
        }

        .ct-left {
            display: inline-flex;
            align-items: center;
            gap: 4px;

            div {
                &:first-child {
                    display: inline-flex;

                    button {
                        &:first-child {
                            border-radius: 4px 0 0 4px;
                        }

                        &:last-child {
                            border-radius: 0 4px 4px 0;
                        }
                    }

                }
            }

        }

        .ct-current-date {
            font-size: 20px;
            color: vars.$text-color-1;
            font-weight: bold;
        }

        .ct-right {
            display: inline-flex;
            gap: 4px;

        }

    }

    .rbc-calendar,
    .rbc-month-view,
    .rbc-time-view,
    .rbc-day-bg,
    .rbc-month-row,
    .rbc-header {
        border: 0 !important;
    }

    .rbc-date-cell {
        text-align: center;

        button {
            font-family: vars.$font-lato;
            color: $text-color-2;
            font-size: 10px;
        }

        &.rbc-off-range {
            button {
                color: lighten($color: $text-color-2, $amount: 50);
            }
        }
    }

    .rbc-row.rbc-month-header {
        margin-bottom: 12px;
    }

    .rbc-header {
        span {
            color: lighten($color: $text-color-2, $amount: 50);
            font-weight: 500;
            font-family: vars.$font-lato;
        }
    }

    .rbc-day-bg {
        &.rbc-off-range-bg {
            background-color: transparent;
        }
    }


    .rbc-event {
        transition: 0.3s opacity;

        &:hover {
            z-index: 99999;

            &+.rbc-event {
                opacity: 0.9;
            }
        }

    }

}
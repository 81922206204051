.reset-password-rapper {
    &>p:first-child {
        margin: 16px 0 68px 0;
        max-width: 424px;
    }

    .custom-btn {
        margin-top: 51px;
        margin-bottom: 114px;
    }
}
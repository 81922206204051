@use '../../../../src/styles/scss/variables' as vars;


.card-images-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;

    .card-image-card {
        width: calc(20% - 30px + 6px);
        height: auto;

        // &:nth-child(5){
        //     width: 20%;
        // }

        .image {
            width: 100%;
            max-height: 146px;
            height: 100%;
            border-radius: 25px;
        }
    }


}

.dashboard-home-wrapper {
    .ant-result-icon {
        &>span {
            color: vars.$primary-color !important;
        }
    }
}